import { InputLabel, Stack, Typography, useTheme } from '@mui/material';
import { QuestionLabelProps } from './types';

export const RequiredQuestionStar = () => {
  const theme = useTheme();
  return <Typography sx={{ color: theme.palette.error.main }}>*</Typography>;
};

export const QuestionLabel: React.FC<QuestionLabelProps> = ({
  dataTestId,
  isRequired,
  questionText,
  sx,
}) => {
  const theme = useTheme();

  return (
    <InputLabel
      data-testid={`${dataTestId}-label`}
      sx={{ color: theme.palette.gray.mediumDark, paddingBottom: theme.spacing(0.5), ...sx }}
    >
      <Stack direction="row">
        <Typography>{questionText}</Typography> &nbsp;
        {isRequired && <RequiredQuestionStar />}
      </Stack>
    </InputLabel>
  );
};
