import { CumulativeScoreQuestionProps } from './types';
import { Label } from 'sharedComponents/Label';
import { Stack, useTheme } from '@mui/material';

export const CumulativeScoreQuestion: React.FC<CumulativeScoreQuestionProps> = ({
  dataTestId,
  maxScore,
  score,
  text,
}) => {
  const theme = useTheme();
  return (
    <>
      <Label
        sx={{ fontWeight: theme.typography.fontWeightMediumBold }}
        labelText={text}
        dataTestId={`${dataTestId}-label`}
      />
      <Stack data-testid={dataTestId}>
        {score} out of {maxScore} possible points
      </Stack>
    </>
  );
};
