import { School } from 'types/connectTypes';

export enum MatchResponseMethods {
  MATCH_CARDS = 'match_cards',
}

export enum MatchResponseActions {
  ACCEPT = 'accept',
  REJECT = 'reject',
  ALREADY_ACTIONED = 'already_actioned',
}

export enum AcceptMatchOptionsLabels {
  SCHEDULE_INTERVIEW = 'Schedule time to chat',
  EMAIL = 'Connect via email',
}

export enum AcceptMatchOptionsValues {
  SCHEDULE_INTERVIEW = 'schedule_interview',
  EMAIL = 'email',
}

export const ACCEPT_MATCH_OPTIONS = [
  {
    label: AcceptMatchOptionsLabels.SCHEDULE_INTERVIEW,
    value: AcceptMatchOptionsValues.SCHEDULE_INTERVIEW,
  },
  {
    label: AcceptMatchOptionsLabels.EMAIL,
    value: AcceptMatchOptionsValues.EMAIL,
  },
];

export enum AlreadyConnectedOptionsLabels {
  EMAIL = 'Email',
  TEXT = 'Text',
  PHONE_CALL = 'Phone call',
  ONSITE_INTERVIEW = 'Onsite interview',
  VIRTUAL_MEETING = 'Virtual meeting (e.g. Zoom)',
  OTHER = 'Other',
}

export enum AlreadyConnectedOptionsValues {
  EMAIL = 'email',
  TEXT = 'text',
  PHONE_CALL = 'phone',
  ONSITE_INTERVIEW = 'on_site_interview',
  VIRTUAL_MEETING = 'virtual_meeting',
  OTHER = 'other',
}

export const ALREADY_CONNECTED_OPTIONS = [
  {
    label: AlreadyConnectedOptionsLabels.EMAIL,
    value: AlreadyConnectedOptionsValues.EMAIL,
  },
  {
    label: AlreadyConnectedOptionsLabels.TEXT,
    value: AlreadyConnectedOptionsValues.TEXT,
  },
  {
    label: AlreadyConnectedOptionsLabels.PHONE_CALL,
    value: AlreadyConnectedOptionsValues.PHONE_CALL,
  },
  // TODO: Uncomment this when Priyanka fixes backend.
  // {
  //   label: AlreadyConnectedOptionsLabels.ONSITE_INTERVIEW,
  //   value: AlreadyConnectedOptionsValues.ONSITE_INTERVIEW,
  // },
  {
    label: AlreadyConnectedOptionsLabels.VIRTUAL_MEETING,
    value: AlreadyConnectedOptionsValues.VIRTUAL_MEETING,
  },
  {
    label: AlreadyConnectedOptionsLabels.OTHER,
    value: AlreadyConnectedOptionsValues.OTHER,
  },
];

export enum RejectMatchOptionsLabels {
  NOT_LOOKING_FOR_TEACHING_JOB = 'No longer looking for a teaching job',
  SCHOOL_TOO_FAR = 'School is too far',
  NO_MATCHING_OPENING = "School doesn't have an opening for the grades or subjects I teach",
  COMPENSATION_BENEFITS_UNMET = "Compensation and benefits didn't meet my criteria",
  WRONG_ENVIRONMENT = 'Not the right environment for me',
  OTHER = 'Other',
}

export enum RejectMatchOptionsValues {
  NOT_LOOKING_FOR_TEACHING_JOB = 'no_longer_looking',
  SCHOOL_TOO_FAR = 'school_too_far',
  NO_MATCHING_OPENING = 'no_matching_opening',
  COMPENSATION_BENEFITS_UNMET = 'compensation_benefits_unmet',
  WRONG_ENVIRONMENT = 'wrong_environment',
  OTHER = 'other',
}

export const REJECT_MATCH_OPTIONS = [
  {
    label: RejectMatchOptionsLabels.NOT_LOOKING_FOR_TEACHING_JOB,
    value: RejectMatchOptionsValues.NOT_LOOKING_FOR_TEACHING_JOB,
  },
  {
    label: RejectMatchOptionsLabels.SCHOOL_TOO_FAR,
    value: RejectMatchOptionsValues.SCHOOL_TOO_FAR,
  },
  {
    label: RejectMatchOptionsLabels.NO_MATCHING_OPENING,
    value: RejectMatchOptionsValues.NO_MATCHING_OPENING,
  },
  {
    label: RejectMatchOptionsLabels.COMPENSATION_BENEFITS_UNMET,
    value: RejectMatchOptionsValues.COMPENSATION_BENEFITS_UNMET,
  },
  {
    label: RejectMatchOptionsLabels.WRONG_ENVIRONMENT,
    value: RejectMatchOptionsValues.WRONG_ENVIRONMENT,
  },
  {
    label: RejectMatchOptionsLabels.OTHER,
    value: RejectMatchOptionsValues.OTHER,
  },
];

export const mockSchool: School = {
  nces_id: '1234567890',
  nimble_id: '1234567890',
  name: 'Mock School',
  district: {
    name: 'Mock District',
    nces_id: '1234567890',
    nimble_id: '1234567890',
  },
  grades: ['Mock Grades'],
  physical_address: {
    line_1: 'Mock Address',
    city: 'Mock City',
    state: 'Mock State',
    zipcode: 'Mock Zip',
    latitude: '0',
    longitude: '0',
  },
  website: 'Mock Website',
  principal: {
    id: 1234567890,
    first_name: 'Mock Principal First Name',
    last_name: 'Mock Principal Last Name',
    email: 'Mock Principal Email',
    image_url: null,
    on_connect: true,
    scheduling_link: null,
  },
  school_metadata: {
    school_type: 'Mock School Type',
    student_count: 100,
  },
  jobpostings: [],
  distance_from_search_location_miles: '0',
  is_recommended: true,
};
