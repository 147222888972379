import { Box, Typography, useTheme } from '@mui/material';
import { Input } from 'sharedComponents/Input';
import { QuestionLabel } from './QuestionLabel';
import { RatingQuestionProps } from './types';

export const RatingQuestion: React.FC<RatingQuestionProps> = ({
  dataTestId,
  defaultValue,
  isRequired,
  maxScore,
  onChange,
  question,
}) => {
  const theme = useTheme();

  return (
    <>
      <QuestionLabel
        dataTestId={dataTestId}
        questionText={question}
        isRequired={isRequired}
        sx={{
          paddingBottom: theme.spacing(0.5),
          fontWeight: theme.typography.fontWeightMediumBold,
        }}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid={dataTestId}>
        <Input
          onChange={onChange}
          value={defaultValue}
          InputProps={{
            inputProps: {
              min: 0,
              max: maxScore,
            },
          }}
          sx={{ width: theme.spacing(6.25), marginRight: theme.spacing(1) }}
        />
        <Typography>out of {maxScore}</Typography>
      </Box>
    </>
  );
};
