import { useEffect, useReducer, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Box } from '@mui/material';
// TODO: Comment back in when we are ready to use State-specific themes
// import { ThemeProvider } from '@mui/material';
import { getTheme } from 'stateThemes';
import { styled } from '@mui/material/styles';
import MarketplaceSchoolsAPI from 'features/Connect/api/marketplaceSchoolsAPI';
import { MoreInfoSlider } from 'features/Connect/components/MoreInfoSlider/MoreInfoSlider';
import { ExpressInterestModal } from 'features/Connect/components/Modals/ExpressInterestModal/ExpressInterestModal';
import { useConnectDiscovery } from 'hooks/data/useConnectDiscovery';
import { useConnectFilterOptions } from 'hooks/data/useConnectFilterOptions';
import { ConnectLocationFilterInterface, School } from 'types/connectTypes';
import { JobDiscoveryFilterContainer } from './JobDiscoveryFilters';
import { JobDiscoverySchoolsList } from './JobDiscoverySchoolsList';
import { buildInitialState, buildUrlSearchParamsFromState, reducer } from './utils';

import {
  JobDiscoveryActionTypes,
  ConnectFilterName,
  ConnectGatedActions,
  ConnectURLActions,
} from 'features/Connect/utils/connectEnums';
import { ExpressInterestExitModal } from 'features/Connect/components/Modals/ExpressInterestExitModal/ExpressInterestExitModal';
import auth from 'utils/auth';
import { ConnectMoreInfoSliderDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../Context/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { Header } from './JobDiscoveryHeader';
import { StateJobBoardTitle } from 'features/Connect/components/StateJobboardComponents/StateJobBoardTitle';
import { AuthenticatedAdminModal } from 'features/Connect/components/Modals/AuthenticatedAdminModal/AuthenticatedAdminModal';
import { userTypeToId } from 'utils/typedEnums';
import { useConnectContext } from '../Context/ConnectContextProvider';
import { ConnectMatchesNotificationBanner } from 'features/Connect/components/ConnectAnnouncementBanner/ConnectMatchesNotificationBanner';

export const JobDiscovery: React.FC = () => {
  const jobboardState = useStateParam();
  const stateCode =
    CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode || CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const selectedSchoolId = searchParams.get('school');
  const gatedAction = searchParams.get('action');
  const vacancyId = searchParams.get('vacancy');
  const authenticatedUser = auth.getUser();
  const connectTheme = getTheme(jobboardState);

  const [state, dispatch] = useReducer(reducer, buildInitialState(search));
  const {
    isExpressInterestModalOpen,
    isExpressInterestExitModalOpen,
    isMoreInfoSliderOpen,
    isUserAuthenticated,
    connectContextActions,
    selectedSchool,
    selectedVacancyId,
    candidateMatches,
  } = useConnectContext();

  const filterValues = {
    ...state,
    state_abbreviation: stateCode,
  };

  const filterOptions = useConnectFilterOptions();

  const jobDiscoveryActions = {
    setPage: (page: number) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_PAGE,
        payload: page,
      }),
    setQuery: (query: string) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_QUERY,
        payload: query,
      }),
    setSelectedSubjectIds: (ids: number[]) => {
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SUBCATEGORIES,
        payload: ids,
      });
    },
    setSelectedDistance: (distance: string) => {
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTANCE_RADIUS_MILES,
        payload: distance,
      });
    },
    setSelectedLocation: (locationFilters: ConnectLocationFilterInterface) => {
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.LOCATION,
        payload: locationFilters,
      });
    },
    setSelectedGrades: (grades: number[]) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.GRADES,
        payload: grades,
      }),
    setSelectedSchoolIds: (ids: number[]) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SCHOOLS,
        payload: ids,
      }),
    setSelectedDistrictIds: (ids: number[]) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTRICTS,
        payload: ids,
      }),
    setFilterIsActive: (filterName: string, isActive: boolean) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_FILTER_IS_ACTIVE,
        filterName,
        payload: isActive,
      }),
    setUseDebounce: (useDebounce: boolean) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_USE_DEBOUNCE,
        payload: useDebounce,
      }),
    setSchools: (schools: School[]) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_SCHOOLS,
        payload: schools,
      }),
    setIsLoadingInitialFilterResults: (isLoadingInitialFilterResults: boolean) =>
      dispatch({
        type: JobDiscoveryActionTypes.SET_IS_LOADING_INITIAL_FILTER_RESULTS,
        payload: isLoadingInitialFilterResults,
      }),
  };

  const { results, hasMore, count, isFetchingNextPage, setIsFetchingNextPage } =
    useConnectDiscovery(
      { ...filterValues },
      state.activeFilters,
      connectContextActions.setIsLoading,
      jobDiscoveryActions.setIsLoadingInitialFilterResults,
      state.useDebounce
    );

  const handleMoreInfoSliderOpen = () => {
    connectContextActions.setIsMoreInfoSliderOpen(true);
    connectContextActions.setIsExpressInterestModalOpen(false);
  };

  const handleMoreInfoSliderClose = () => {
    connectContextActions.setIsMoreInfoSliderOpen(false);
    connectContextActions.setSelectedSchool(null);
    connectContextActions.setSelectedVacancyId('0');
  };

  const handleUpdateContactedSchools = (contactedSchool: School) => {
    const updatedSchools = state.schools.map((school) => {
      if (school.nces_id === contactedSchool.nces_id) {
        return {
          ...school,
          hasExpressedInterest: true,
        };
      }
      return school;
    });
    jobDiscoveryActions.setSchools(updatedSchools);
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      connectContextActions.setIsLoading(true);
      try {
        const school = await MarketplaceSchoolsAPI.getSchoolByNcesId({
          stateCode: stateCode,
          ncesId: selectedSchoolId,
        });
        connectContextActions.setSelectedSchool(school);
        if (gatedAction === ConnectURLActions.INFO) {
          connectContextActions.setSelectedVacancyId(vacancyId);
          connectContextActions.setIsMoreInfoSliderOpen(true);
        } else if (vacancyId) {
          connectContextActions.setSelectedVacancyId(vacancyId);
          connectContextActions.setIsMoreInfoSliderOpen(true);
          connectContextActions.setIsReadyToConnectModalOpen(true);
        } else if (gatedAction === ConnectGatedActions.EXPRESS_INTEREST) {
          connectContextActions.setIsExpressInterestModalOpen(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        connectContextActions.setIsLoading(false);
      }
    };

    if (results && selectedSchoolId && !selectedSchool) {
      fetchSchoolData();
    }
    jobDiscoveryActions.setSchools(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, selectedSchoolId, vacancyId]);

  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  useEffect(() => {
    const isAuthenticated = !!authenticatedUser?.profile;

    if (isAuthenticated && candidateMatches?.pending?.length > 0) {
      setShouldShowBanner(true);
    } else {
      setShouldShowBanner(false);
    }

    if (isAuthenticated && authenticatedUser.profile.user_type !== userTypeToId.candidate) {
      connectContextActions.setIsAuthenticatedAdminModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser, candidateMatches]);

  useEffect(
    () => {
      const newParams = buildUrlSearchParamsFromState({
        searchParams: searchParams,
        isMoreInfoSliderOpen: isMoreInfoSliderOpen,
        selectedVacancyId: selectedVacancyId,
        selectedSchoolId: selectedSchool?.nces_id,
        schoolNcesIds: state.school_nces_ids,
        grades: state.grades,
        subcategories: state.subcategories,
        districtNcesIds: state.district_nces_ids,
      });

      history.replace({ search: newParams.toString() });
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      isMoreInfoSliderOpen,
      selectedVacancyId,
      selectedSchool?.nces_id,
      state.school_nces_ids,
      state.grades,
      state.subcategories,
      state.district_nces_ids,
    ] /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // TODO: Comment back in when we are ready to use State-specific themes
    // <ThemeProvider theme={connectTheme}>
    <ConnectJobboardContainer>
      {shouldShowBanner && <ConnectMatchesNotificationBanner />}
      {!authenticatedUser?.preference?.nimble_connect_profile?.is_completed && (
        <Header isUserAuthenticated={isUserAuthenticated} />
      )}
      <JobDiscoveryContainer>
        {isUserAuthenticated &&
          authenticatedUser?.profile?.user_type !== userTypeToId.candidate && (
            <AuthenticatedAdminModal
              district={authenticatedUser?.profile?.district}
              jobboardState={jobboardState}
            />
          )}
        {isExpressInterestModalOpen && (
          <ExpressInterestModal
            school={selectedSchool}
            updateContactedSchools={handleUpdateContactedSchools}
          />
        )}
        {isExpressInterestExitModalOpen && <ExpressInterestExitModal />}
        {isMoreInfoSliderOpen && (
          <MoreInfoSlider
            closeHandler={handleMoreInfoSliderClose}
            openHandler={handleMoreInfoSliderOpen}
            anchor={'right'}
            school={selectedSchool}
            dataTestId={ConnectMoreInfoSliderDataTestIds.MORE_INFO_SLIDER}
          />
        )}
        <Box maxWidth={'1130px'} width={'100%'}>
          <StateJobBoardTitle sx={JobboardTitleStyles(connectTheme)} titleTextSize="" />
          <JobDiscoveryFilterContainer
            filterValues={filterValues}
            jobDiscoveryActions={jobDiscoveryActions}
            filterOptions={filterOptions}
            activeFilters={state.activeFilters}
          />
        </Box>
        <Box maxWidth={'1130px'} width={'100%'}>
          <JobDiscoverySchoolsList
            schools={state.schools}
            hasMore={hasMore}
            count={count}
            page={state.page}
            isFetchingNextPage={isFetchingNextPage}
            isLoadingInitialFilterResults={state.isLoadingInitialFilterResults}
            setIsFetchingNextPage={setIsFetchingNextPage}
            jobDiscoveryActions={jobDiscoveryActions}
          />
        </Box>
      </JobDiscoveryContainer>
    </ConnectJobboardContainer>
    // </ThemeProvider>
  );
};

const ConnectJobboardContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(7),
  },
}));

const JobDiscoveryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  flexDirection: 'column',
  alignItems: 'center',
  background: theme.palette.white.main,

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px 20px',
  },
}));

const JobboardTitleStyles = (theme) => ({
  display: { xs: 'flex', md: 'none' },
  alignSelf: 'center',
  textAlign: 'center',
  gap: 1,
  margin: `${theme.spacing(4)} ${theme.spacing(5)}`,
});
