import { FormProvider, useForm } from 'react-hook-form';
import { InputLabel, Typography } from '@mui/material';

import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';
import { CreateForm } from './CreateForm';
import { TemplateSelectionFormContainer, SelectContainer } from './styles';
import { TemplateSelectionFormProps, ScorecardToSubmit } from './types';

export const TemplateSelectionForm: React.FC<TemplateSelectionFormProps> = ({
  candidateName,
  modalType,
  onCancel,
  roleId,
}) => {
  const labelText = modalType === 'scorecard' ? 'Scorecard template' : 'Reference template';
  const titleText =
    modalType === 'scorecard'
      ? `New scorecard for ${candidateName}`
      : `New reference for ${candidateName}`;
  const methods = useForm<ScorecardToSubmit>();

  const onSubmit = (data: ScorecardToSubmit) => {
    console.log(data);
  };

  return (
    <TemplateSelectionFormContainer>
      <Typography
        variant="h2"
        data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.CREATE_PANE_TITLE}
      >
        {titleText}
      </Typography>
      <SelectContainer>
        <InputLabel
          data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.CREATE_PANE_LABEL_TEXT}
        >
          {labelText}
        </InputLabel>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CreateForm roleId={roleId} onCancel={onCancel} />
          </form>
        </FormProvider>
      </SelectContainer>
    </TemplateSelectionFormContainer>
  );
};
