import { Controller, useFormContext } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material/Select';

import { assignQuestionProperties, assignTopLevelScorecardProperties } from './util';
import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';
import { AvailableScorecardData } from '../queries';
import { MenuItem, Select } from '@mui/material';

export const TemplateSelect: React.FC<{ scorecards: AvailableScorecardData[] }> = ({
  scorecards,
}) => {
  const { setValue } = useFormContext();

  const handleScorecardChange = (event: SelectChangeEvent, onChange: (id: number) => void) => {
    const selectedScorecardId = Number(event.target.value);
    const scorecard = scorecards.find((scorecard) => scorecard.id === selectedScorecardId);
    onChange(selectedScorecardId);
    assignTopLevelScorecardProperties(scorecard?.questions, setValue);
    assignQuestionProperties(scorecard?.questions, setValue);
  };

  return (
    <Controller
      name="id"
      render={({ field: { onChange, value } }) => (
        <Select
          data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.TEMPLATE_SELECT}
          displayEmpty
          onChange={(event) => handleScorecardChange(event, onChange)}
          sx={{ width: '100%' }}
          value={value || ''}
        >
          <MenuItem value="" disabled>
            Select a template
          </MenuItem>
          {scorecards.map((scorecard) => (
            <MenuItem key={scorecard.id} value={scorecard.id}>
              {scorecard.title}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};
