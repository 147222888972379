import { SxProps, useTheme } from '@mui/material';
import { SecondaryButton } from './SecondaryButton';
import { secondaryButtonOverrideSx } from './styles';

export interface GreySecondaryButtonProps {
  children: string;
  dataTestId: string;
  onClick: () => void;
  startIcon?: JSX.Element;
  sx?: SxProps;
}

export const GreySecondaryButton: React.FC<GreySecondaryButtonProps> = ({
  children,
  dataTestId,
  onClick,
  startIcon,
  sx,
}) => {
  const theme = useTheme();

  return (
    <SecondaryButton
      onClick={onClick}
      startIcon={startIcon}
      sx={{ ...secondaryButtonOverrideSx(theme), ...sx }}
      dataTestId={dataTestId}
    >
      {children}
    </SecondaryButton>
  );
};
