import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';
import { QuestionAndAnswerSection } from './QuestionAndAnswerSection';
import { QuestionContainer, TemplateTitle } from './styles';
import { QuestionsSubFormProps } from './types';

export const QuestionsSubForm: React.FC<QuestionsSubFormProps> = ({
  questions,
  scorecardTitle,
}) => {
  return (
    <div data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.QUESTION_SUB_FORM}>
      <TemplateTitle
        data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.SELECTED_TEMPLATE_TITLE}
        variant="h3"
      >
        Template: {scorecardTitle}
      </TemplateTitle>
      {questions.map((question, index) => {
        return (
          <QuestionContainer key={`${index}-${question.question_text}`}>
            <QuestionAndAnswerSection question={question} index={index} />
          </QuestionContainer>
        );
      })}
    </div>
  );
};
