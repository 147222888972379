import { CreatePaneContainer } from '../../styles';
import { CreatePaneProps } from '../types';
import { TemplateSelectionForm } from './components/TemplateSelectionForm';

export const CreatePane: React.FC<CreatePaneProps> = ({
  candidateName,
  modalType,
  roleId,
  onCancel,
}) => {
  return (
    <CreatePaneContainer>
      <TemplateSelectionForm
        candidateName={candidateName}
        modalType={modalType}
        onCancel={onCancel}
        roleId={roleId}
      />
    </CreatePaneContainer>
  );
};
