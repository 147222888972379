import { useTheme } from '@mui/material';

import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';
import { CTAButtonsContainer, Right } from './styles';
import { CTAButtonsProps } from './types';
import { GreySecondaryButton } from 'sharedComponents/Buttons/GreySecondaryButton';
import { PrimaryButton } from 'sharedComponents/Buttons';
import {
  primaryButtonOverrideSx,
  secondaryButtonOverrideSx,
} from 'sharedComponents/Buttons/styles';

export const CTAButtons: React.FC<CTAButtonsProps> = ({
  hasSelectedScorecard,
  onCancel,
  onSaveAsDraft,
}) => {
  const theme = useTheme();

  return (
    <CTAButtonsContainer>
      <GreySecondaryButton
        dataTestId={ATSConnectHiringRequestCreateFeedbackTestIds.CANCEL_BUTTON}
        onClick={onCancel}
        sx={{
          width: !hasSelectedScorecard ? '50%' : '25%',
          marginRight: !hasSelectedScorecard ? theme.spacing(1) : 'inherit',
        }}
      >
        Cancel
      </GreySecondaryButton>
      <Right>
        {hasSelectedScorecard && (
          <GreySecondaryButton
            dataTestId={ATSConnectHiringRequestCreateFeedbackTestIds.SAVE_AS_DRAFT_BUTTON}
            onClick={onSaveAsDraft}
            sx={{ ...secondaryButtonOverrideSx(theme), marginRight: theme.spacing(1) }}
          >
            Save as draft
          </GreySecondaryButton>
        )}
        <PrimaryButton
          dataTestId={ATSConnectHiringRequestCreateFeedbackTestIds.SUBMIT_BUTTON}
          sx={{
            ...primaryButtonOverrideSx(theme),
            width: !hasSelectedScorecard ? '100%' : 'inherit',
          }}
          type="submit"
          disabled={!hasSelectedScorecard}
        >
          Submit
        </PrimaryButton>
      </Right>
    </CTAButtonsContainer>
  );
};
