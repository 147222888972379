export enum NavbarV2DataTestIds {
  SIGN_UP_BUTTON = 'navbar-v2-sign-up-button',
  USER_MENU = 'navbar-v2-user-menu-button',
  USER_MENU_MATCH_DASHBOARD = 'navbar-v2-user-menu-button-match-dashboard',
  USER_MENU_PROFILE = 'navbar-v2-user-menu-button-profile',
  USER_MENU_LOGOUT = 'navbar-v2-user-menu-button-logout',
  USER_MENU_RELEASE_HIJACK = 'navbar-v2-user-menu-button-release-hijack',
  USER_MENU_SUPPORT = 'navbar-v2-user-menu-button-support',
  NAVBAR_MISSOURI_LOGO = 'missouri-navbar-logo',
  NAVBAR_TENNESSEE_LOGO = 'tennessee-navbar-logo',
  NOTIFICATION_BADGE = 'navbar-v2-notification-badge',
}
