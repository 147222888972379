export const activeValueByActiveStatus = {
  0: 'Active, closed',
  1: 'Active, open',
} as const;

export type ActiveStatus = keyof typeof activeValueByActiveStatus;

export const roleStatusByValue = {
  draft: 0,
  submitted: 10,
  active: 20,
  archived: 30,
} as const;

export const roleStatusesForJobView = {
  0: 'Draft',
  10: 'Submitted',
  20: 'Active',
  30: 'Archived',
} as const;

export const userTypeToId = {
  candidate: 0,
  schoolUser: 10,
  schoolAdmin: 20,
  districtUser: 30,
  districtAdmin: 40,
  superAdmin: 50,
} as const;

export const categoryEnumToName = {
  0: 'High needs',
  1: 'Language arts',
  2: 'STEM',
  3: 'Athletics',
} as const;

export const HIRING_SEASONS = {
  0: 'N/A',
  1: 'SY 17-18',
  2: 'SY 18-19',
  3: 'SY 19-20',
  4: 'SY 20-21',
  5: 'SY 21-22',
  6: 'SY 22-23',
  7: 'SY 23-24',
  8: 'SY 24-25',
  9: 'SY 25-26',
  10: 'SY 26-27',
  11: 'SY 27-28',
  12: 'SY 28-29',
} as const;

export const applicationStatus = {
  draft: 0,
  pre_hiring_pool: 1,
  hiring_pool: 2,
  post_hiring_pool: 3,
  hired: 4,
  archived: 5,
  offer_declined: 6,
  onboarding: 7,
} as const;

export const languages = {
  1: 'english',
  2: 'spanish',
  3: 'russian',
  4: 'japanese',
  5: 'italian',
  6: 'french',
  7: 'chinese',
  8: 'american_sign',
  9: 'latin',
  10: 'german',
  11: 'korean',
  12: 'mandarin',
  13: 'punjabi',
  14: 'vietnamese',
  15: 'arabic',
  16: 'portuguese',
  18: 'hmong',
  19: 'lao',
  20: 'armenian',
  21: 'farsi',
  999: 'other',
} as const;

export type UserType = keyof typeof userTypeToId;

export type JobStatus = keyof typeof roleStatusByValue;
export type JobStatusByNumber = keyof typeof roleStatusesForJobView;

export const scorecardQType = {
  rating: 0,
  yes_no: 1,
  text: 2,
  nimble: 3,
  multiple_choice: 4,
  direction_text: 5,
  rubric: 6,
  final_recommendation: 7,
  cumulative_score: 8,
  attachment: 9,
} as const;

export type ScorecardQuestionTypeNumber = (typeof scorecardQType)[keyof typeof scorecardQType];
