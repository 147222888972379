import { Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';
import { CumulativeScoreQuestion } from 'sharedComponents/Questions/components/CumulativeScoreQuestion';
import { FinalRecommendationQuestion } from 'sharedComponents/Questions/components/FinalRecommendationQuestion';
import {
  MultiSelectMultipleChoice,
  SingleMultipleChoiceQuestion,
} from 'sharedComponents/Questions/components/MultipleChoiceQuestion';
import { QuestionAndAnswerSectionProps } from './types';
import { RatingQuestion } from 'sharedComponents/Questions/components/RatingQuestion';
import { RubricQuestion } from 'sharedComponents/Questions/components/RubricQuestion';
import { scorecardQType } from 'utils/enums';
import { TextQuestion } from 'sharedComponents/Questions/components/TextQuestion';
import { useUpdateCumulativeScore } from './util';

const QuestionController = ({ name, children }) => (
  <Controller
    name={name}
    control={useFormContext().control}
    render={({ field: { onChange, value } }) => children({ onChange, value })}
  />
);

export const QuestionAndAnswerSection: React.FC<QuestionAndAnswerSectionProps> = ({
  question,
  index,
}) => {
  const { watch } = useFormContext();
  const shouldShowRatingQuestions = watch('include_rating_questions');
  const shouldShowRubricQuestions = watch('include_rubric_questions');
  const updateRatingScore = useUpdateCumulativeScore(shouldShowRatingQuestions);
  const updateRubricScore = useUpdateCumulativeScore(shouldShowRubricQuestions);

  switch (question.question_type) {
    case scorecardQType.rating:
      return (
        <QuestionController name={`answers.${index}.answer_rating`}>
          {({ onChange }) => (
            <RatingQuestion
              dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.RATING_QUESTION}-${question.id}`}
              isRequired={question.is_required}
              maxScore={question.scale}
              question={question.question_text}
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                onChange(value);
                updateRatingScore(value);
              }}
            />
          )}
        </QuestionController>
      );
    case scorecardQType.nimble:
    case scorecardQType.text:
      return (
        <QuestionController name={`answers.${index}.answer_text`}>
          {({ onChange, value }) => (
            <TextQuestion
              dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.NIMBLE_OR_TEXT_QUESTION}-${question.id}`}
              defaultValue={value}
              isRequired={question.is_required}
              multiline
              onChange={(e) => onChange(e.target.value)}
              question={question.question_text}
              rows={4}
            />
          )}
        </QuestionController>
      );
    case scorecardQType.multiple_choice:
      return (
        <QuestionController name={`answers.${index}.mc_answer`}>
          {({ onChange, value }) =>
            question.multi_select ? (
              <MultiSelectMultipleChoice
                dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.MULTIPLE_CHOICE_QUESTION}-${question.id}`}
                isRequired={question.is_required}
                items={(question.mc_options || []).map((option) => ({
                  id: option.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0),
                  isChecked: false,
                  title: option,
                }))}
                question={question.question_text}
                onChange={onChange}
              />
            ) : (
              <SingleMultipleChoiceQuestion
                dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.MULTIPLE_CHOICE_QUESTION}-${question.id}`}
                defaultValue={value}
                isRequired={question.is_required}
                items={question.mc_options || []}
                onChange={onChange}
                question={question.question_text}
              />
            )
          }
        </QuestionController>
      );
    case scorecardQType.cumulative_score:
      return (
        <QuestionController name="cumulative_score">
          {() => {
            const cumulativeScore = watch('cumulative_score');
            const maxCumulativeScore = watch('max_cumulative_score');

            return (
              <CumulativeScoreQuestion
                dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.CUMULATIVE_SCORE_QUESTION}-${question.id}`}
                maxScore={maxCumulativeScore}
                score={cumulativeScore}
                text={'Cumulative score:'}
              />
            );
          }}
        </QuestionController>
      );
    case scorecardQType.direction_text:
      return (
        <Typography
          dangerouslySetInnerHTML={{
            __html: question.directions,
          }}
          data-testid={`${ATSConnectHiringRequestCreateFeedbackTestIds.DIRECTION_TEXT_QUESTION}-${question.id}`}
          variant="body1"
        />
      );
    case scorecardQType.final_recommendation:
      return (
        <QuestionController name="final_recommendation">
          {({ onChange, value }) => (
            <FinalRecommendationQuestion
              dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.FINAL_RECOMMENDATION_QUESTION}-${question.id}`}
              defaultValue={value}
              includeMaybeOption={question.include_maybe_option}
              isRequired={question.is_required}
              onToggle={onChange}
              questionText={question.question_text}
            />
          )}
        </QuestionController>
      );
    case scorecardQType.rubric:
      return (
        <QuestionController name={`answers.${index}.answer_rubric`}>
          {({ onChange, value }) => (
            <RubricQuestion
              currentlySelected={value}
              dataTestId={`${ATSConnectHiringRequestCreateFeedbackTestIds.RUBRIC_QUESTION}-${question.id}`}
              isRequired={question.is_required}
              onChange={(newValue) => {
                onChange(newValue);
                updateRubricScore(newValue);
              }}
              rows={question.rubric_columns}
              title={question.question_text}
            />
          )}
        </QuestionController>
      );
    case scorecardQType.attachment:
      return <></>;
    default:
      return <div />;
  }
};
