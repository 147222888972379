import { EndOfMatchesIcon, NoPendingMatchesIcon } from './styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getConnectUrlPath } from 'features/Connect/features/JobDiscovery/utils';

export const CandidatePortalRoutes = {
  MATCHES: '/candidate/matches',
};

const jobboardReturnButtonText = 'Return to Job Board';

export const ViewState = {
  NO_PENDING_MATCHES: {
    primaryText: 'You have no pending matches',
    secondaryText: 'Check back soon for more!',
    icon: NoPendingMatchesIcon,
    buttonStartIcon: <ArrowBackIcon />,
    buttonText: jobboardReturnButtonText,
    buttonAction: (jobboardState: string) => {
      window.location.href = getConnectUrlPath(jobboardState);
    },
    requiresStateParam: true,
  },
  END_OF_MATCHES: {
    primaryText: "Congrats!\nYou've viewed all\nyour matches.",
    secondaryText: 'Check back soon\nfor more!',
    icon: EndOfMatchesIcon,
    buttonStartIcon: <ArrowBackIcon />,
    buttonText: jobboardReturnButtonText,
    buttonAction: (jobboardState: string) => {
      window.location.href = getConnectUrlPath(jobboardState);
    },
    requiresStateParam: true,
  },
  NO_LONGER_ACTIVE: {
    primaryText: "Uh-oh!\nLooks like you're no longer active",
    secondaryText:
      "Would you like to mark yourself as 'open to work' and 'open to being contacted by principals' to reactivate your matching account?",
    icon: EndOfMatchesIcon,
    buttonText: 'Yes, Reactivate me!',
    buttonAction: () => {
      // TODO: Implement reactivation logic post mvp
      console.log('Reactivate me!');
    },
    requiresStateParam: false,
  },
};
