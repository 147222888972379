import axios from 'axios';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import Notifications from 'react-notify-toast';
import { theme } from 'ui-kit';
import { Footer } from './components/footer';
import Navbar from './components/NavBar/navbar';
import { NavbarV2 } from './components/NavBar/NavbarV2';
import { initSegment } from './utils/segment';
// import utils and check for IE
import { ConnectAlertsBanner } from 'features/Connect/components/ConnectAlertsBanner/ConnectAlertsBanner';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import { useRouteMatch } from 'react-router-dom';
import { startRecurringAuthCheck } from './utils/authutils';
import { getInternetExplorerVersion } from './utils/util';
import auth from 'utils/auth';

interface LayoutProps {
  children: React.ReactNode;
}

type SetNotFoundContextValue = () => () => void;

const SetNotFoundContext = createContext<SetNotFoundContextValue>(() => () => {});

export const useSetNotFound = () => useContext(SetNotFoundContext);

declare global {
  interface Analytics {
    page: () => void;
  }

  interface Window {
    analytics: Analytics;
  }
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [notFound, setNotFound] = useState(false);
  const { userType, hasApplications } = useCurrentUserPermissions();

  const handleSetNotFound = useCallback(() => {
    setNotFound(true);

    return () => {
      setNotFound(false);
    };
  }, []);

  const jobMatch = useRouteMatch('/jobs/');
  const onLoginPage = useRouteMatch('/login');
  const onJobDetailsPage = useRouteMatch('/jobs/:id/details');
  const shouldRenderNavBar = (!jobMatch && !notFound && !onLoginPage) || onJobDetailsPage;
  const atConnectRoute = useRouteMatch('/connect');
  const atCandidatePortalRoute = useRouteMatch('/candidate/:id');
  const atConnectOrCandidatePortalRoute = !!atConnectRoute || !!atCandidatePortalRoute;

  useEffect(() => {
    if (window.analytics) {
      window.analytics.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const setHelloSignVariable = (value) => {
      window.localStorage.setItem('hellosign_setting', value);
    };

    // Check for IE and add class
    if (getInternetExplorerVersion() !== -1) {
      document.body.classList.add('ie');
    }

    let isUnmounted = false;

    axios.get('/api/config/').then((r) => {
      if (!isUnmounted && r.data.SEGMENT_KEY) {
        initSegment(r.data.SEGMENT_KEY);
      }
      setHelloSignVariable(r.data.HELLOSIGN_SETTING);
    });

    startRecurringAuthCheck();

    return () => {
      isUnmounted = true;
    };
  }, []);

  const atATSConnectIntegrationRoute = useRouteMatch('/connect/import-candidates');
  const atDistrictJobsList = useRouteMatch('/district/jobslist');
  const atSchoolJobsList = useRouteMatch('/school/jobslist');
  const atTrevorDashboards = useRouteMatch('/dashboard/oecosl');
  const jobsDiscoveryConnect = useRouteMatch({ path: '/connect', exact: true });
  const shouldNotShowFooter = atTrevorDashboards || atDistrictJobsList || atSchoolJobsList;

  return (
    <ThemeProvider theme={theme}>
      <Notifications />
      {jobsDiscoveryConnect && auth.hasConnectJobAlerts() && <ConnectAlertsBanner />}
      {atConnectOrCandidatePortalRoute && !atATSConnectIntegrationRoute && <NavbarV2 />}
      {(!atConnectOrCandidatePortalRoute || atATSConnectIntegrationRoute) && (
        <Navbar
          show={shouldRenderNavBar}
          onProspectList={window.location.href.indexOf('prospectlist') !== -1}
          onProspectEntry={window.location.href.indexOf('prospectentry') !== -1}
          userType={userType}
          hasApplications={hasApplications}
        />
      )}

      <SetNotFoundContext.Provider value={handleSetNotFound}>
        {children}
      </SetNotFoundContext.Provider>
      {!shouldNotShowFooter && <Footer />}
    </ThemeProvider>
  );
};

export default Layout;
