import { Box, styled, Typography } from '@mui/material';

export const CTAButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(6),
  width: '100%',
}));

export const TemplateSelectionFormContainer = styled(Box)({
  alignItems: 'start',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const QuestionContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));

export const Right = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasSelectedScorecard',
})<{ hasSelectedScorecard?: boolean }>(({ hasSelectedScorecard }) => ({
  display: 'flex',
  justifyContent: 'end',
  width: !hasSelectedScorecard ? '50%' : 'inherit',
}));

export const SelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  width: '100%',
}));

export const TemplateTitle = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));
