import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import customScorecardListAPI from 'api/customScorecardListAPI';
import { ScorecardViewPermissions } from 'features/TabbedJobView/HiringTeamTab/types';
import { FeedbackQuestion } from './components/types';

export interface AvailableScorecardData {
  id: number;
  title: string;
  view_permissions: ScorecardViewPermissions;
  questions: FeedbackQuestion[];
}

export const useQueryGetAvailableScorecardsByRoleData = (
  roleId: number,
  options?: Omit<UseQueryOptions<AvailableScorecardData[], AxiosError>, 'queryKey' | 'queryFn'>
): UseQueryResult<AvailableScorecardData[], AxiosError> => {
  return useQuery({
    queryKey: ['availableScorecardsForRole', roleId],
    queryFn: () =>
      customScorecardListAPI.fetchCustomScorecardsByRole(roleId, true).then((res) => res),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  });
};
