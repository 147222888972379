import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import { CheckboxList } from 'sharedComponents/List/CheckboxList';
import {
  MultiSelectMultipleChoiceProps,
  NestedMultiSelectMultipleChoiceQuestionProps,
  SingleMultipleChoiceQuestionProps,
} from './types';
import { SelectAllList } from 'sharedComponents/List/SelectAllList';
import { QuestionLabel, RequiredQuestionStar } from './QuestionLabel';

export const NestedMultiSelectMultipleChoiceQuestion: React.FC<
  NestedMultiSelectMultipleChoiceQuestionProps
> = ({ question, dataTestId, isRequired, items, label }) => {
  return (
    <>
      <QuestionLabel isRequired={isRequired} questionText={question} dataTestId={dataTestId} />
      <SelectAllList
        formControlLabelSx={{ marginBottom: 0 }}
        dataTestId={dataTestId}
        label={label}
        items={items}
      />
    </>
  );
};

export const SingleMultipleChoiceQuestion: React.FC<SingleMultipleChoiceQuestionProps> = ({
  dataTestId,
  defaultValue,
  items,
  isRequired,
  question,
  onChange,
}) => {
  return (
    <FormControl>
      <Stack direction="row">
        <FormLabel data-testid={`${dataTestId}-label`}>{question}</FormLabel>&nbsp;
        {isRequired && <RequiredQuestionStar />}
      </Stack>
      <RadioGroup value={defaultValue?.[0] || ''} onChange={(e) => onChange?.([e.target.value])}>
        {items.map((item) => (
          <FormControlLabel control={<Radio />} key={item} label={item} value={item} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const MultiSelectMultipleChoice: React.FC<MultiSelectMultipleChoiceProps> = ({
  dataTestId,
  items,
  isRequired,
  onChange,
  question,
}) => {
  return (
    <>
      <Stack direction={'row'}>
        <QuestionLabel dataTestId={dataTestId} questionText={question} />
        <Typography>Select all that apply.</Typography> &nbsp;
        {isRequired && <RequiredQuestionStar />}
      </Stack>
      <CheckboxList
        dataTestId={dataTestId}
        disableGutters
        disableRipple
        listItemButtonSx={{
          '&.MuiButtonBase-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
        listItemIconSx={{
          minWidth: 0,
        }}
        listSx={{
          padding: 0,
          li: { padding: 0 },
          '.MuiButtonBase-root': {
            paddingLeft: '8px',
          },
        }}
        isDense
        onChange={onChange}
        options={items}
      />
    </>
  );
};
