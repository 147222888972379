import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { QuestionLabel } from './QuestionLabel';
import { RubricCellProps, RubricQuestionProps } from './types';

export const RubricCell: React.FC<RubricCellProps> = ({
  currentlySelected,
  dataTestId,
  row,
  onClick,
}) => {
  const theme = useTheme();
  const isSelected = currentlySelected === row.number;
  const currentRowId = `${dataTestId}-cell-${row.number}`;

  return (
    <RubricCellContainer
      onClick={() => onClick(row.number)}
      data-testid={currentRowId}
      sx={{
        backgroundColor: isSelected ? theme.palette.primary.fill : 'transparent',
        border: isSelected
          ? `2px solid ${theme.palette.success.main}`
          : `1px solid ${theme.palette.gray.mediumLight}`,
      }}
    >
      <Typography
        data-testid={`${currentRowId}-number`}
        sx={{ fontWeight: isSelected ? theme.typography.fontWeightBold : null }}
      >
        {row.number}&nbsp;
      </Typography>
      {row.label && (
        <Typography
          data-testid={`${currentRowId}-label`}
          sx={{ fontWeight: isSelected ? theme.typography.fontWeightMediumBold : null }}
        >
          {row.label}&nbsp;
        </Typography>
      )}
      {row.description && (
        <Typography data-testid={`${currentRowId}-description`} sx={{ fontSize: '12px' }}>
          {row.description}
        </Typography>
      )}
    </RubricCellContainer>
  );
};

export const RubricQuestion: React.FC<RubricQuestionProps> = ({
  currentlySelected,
  dataTestId,
  isRequired,
  onChange,
  rows,
  title,
}) => {
  const theme = useTheme();

  return (
    <>
      <QuestionLabel
        dataTestId={dataTestId}
        isRequired={isRequired}
        sx={{
          paddingBottom: theme.spacing(1),
          fontWeight: theme.typography.fontWeightMediumBold,
        }}
        questionText={title}
      />

      <Stack direction={'row'}>
        {rows.map((row) => (
          <RubricCell
            currentlySelected={currentlySelected}
            key={row.number}
            row={row}
            dataTestId={dataTestId}
            onClick={(rowNumber: number) => {
              onChange(rowNumber);
            }}
          />
        ))}
      </Stack>
    </>
  );
};

const RubricCellContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  textAlign: 'center',
  width: theme.spacing(17.5),
}));
