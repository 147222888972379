import { TextQuestionProps } from './types';
import { useTheme } from '@mui/material';
import { QuestionLabel } from './QuestionLabel';
import { Input } from 'sharedComponents/Input';

export const TextQuestion: React.FC<TextQuestionProps> = ({
  dataTestId,
  answer,
  isRequired,
  multiline,
  onChange,
  placeholder,
  question,
  rows,
}) => {
  const theme = useTheme();

  return (
    <>
      <QuestionLabel questionText={question} isRequired={isRequired} dataTestId={dataTestId} />
      <Input
        dataTestId={dataTestId}
        defaultValue={answer}
        InputProps={{ sx: { borderRadius: theme.spacing(1) } }}
        label={question}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
      />
    </>
  );
};
