import { BlankModal } from 'sharedComponents/Modal';
import { ModalButtonGroup } from '../../Buttons/ModalButtonGroup';
import { useState } from 'react';
import { ModalOptionGroup } from '../../ModalFormOptions/ModalOptionGroup';
import {
  ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds,
  ConnectCandidateMatchDashboardDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import { useTheme, Box } from '@mui/material';
import { ModalHeader, ModalStyles } from '../utils/styles';
import {
  ALREADY_CONNECTED_OPTIONS,
  AlreadyConnectedOptionsValues,
  MatchResponseMethods,
  MatchResponseActions,
} from '../utils/constants';
import CandidateSchoolActionsAPI from '../../../api/candidateMatchActionsAPI';
import { AlreadyConnectedModalProps } from '../../types';

export const AlreadyConnectedModal = ({
  isOpen,
  onClose,
  currentMatch,
  onSubmit,
}: AlreadyConnectedModalProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const theme = useTheme();
  const school = currentMatch?.school;

  const submitAlreadyConnected = async () => {
    await CandidateSchoolActionsAPI.createSchoolConnectAction({
      school_nces_id: school?.nces_id,
      response_platform: AlreadyConnectedOptionsValues.OTHER,
      response_action: MatchResponseActions.ALREADY_ACTIONED,
      reason: selectedOptions.join(', '),
      original_response_method: MatchResponseMethods.MATCH_CARDS,
    });

    onSubmit();
    onClose();
  };

  const CancelButton = {
    label: 'Cancel',
    action: onClose,
    dataTestId: ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.CANCEL_BUTTON,
  };

  const SubmitButton = {
    label: 'Submit',
    action: submitAlreadyConnected,
    dataTestId: ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.SUBMIT_BUTTON,
    color: 'black',
    disabled: selectedOptions.length === 0,
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(value)) {
        return prevOptions.filter((option) => option !== value);
      } else {
        return [...prevOptions, value];
      }
    });
  };

  const modalHeader = (
    <ModalHeader
      data-testid={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.HEADER_TEXT}
    >
      How have you connected with this Principal?
    </ModalHeader>
  );

  const modalButtonGroup = () => {
    return (
      <ModalButtonGroup
        secondaryButton={CancelButton}
        primaryButton={SubmitButton}
        dataTestId={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.OPTION_GROUP}
        sx={{ marginTop: theme.spacing(1) }}
      />
    );
  };

  const modalBody = () => {
    return (
      <Box>
        <ModalOptionGroup
          modalOptions={ALREADY_CONNECTED_OPTIONS}
          selectedOption={selectedOptions}
          handleReasonChange={handleOptionChange}
          dataTestId={ConnectCandidateMatchDashboardAlreadyConnectedModalDataTestIds.OPTION_GROUP}
          optionType="checkbox"
          isMultiSelect={true}
        />
        {modalButtonGroup()}
      </Box>
    );
  };

  return (
    <BlankModal
      dataTestId={ConnectCandidateMatchDashboardDataTestIds.ALREADY_CONNECTED_MODAL}
      header={modalHeader}
      body={modalBody()}
      open={isOpen}
      onClose={onClose}
      sx={ModalStyles(theme)}
    />
  );
};
