import { useState, useEffect, useRef } from 'react';
import { Box, Link, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AnnouncementBanner } from 'sharedComponents/Banner/AnnouncementBanner';
import { ConnectProfileFlowDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
import auth from 'utils/auth';
import { styled } from '@mui/material/styles';

export const ConnectMatchesNotificationBanner: React.FC = () => {
  const { isUserAuthenticated, candidateMatches } = useConnectContext();
  const [shouldRender, setShouldRender] = useState(false);
  const totalPendingMatches = candidateMatches?.pending?.length;
  const theme = useTheme();
  const prevAuthRef = useRef(isUserAuthenticated);
  const authenticatedUser = auth.getUser();

  useEffect(() => {
    prevAuthRef.current = isUserAuthenticated;
  }, [isUserAuthenticated, authenticatedUser]);

  useEffect(() => {
    const isAuthenticated = !!authenticatedUser?.profile;

    if (isAuthenticated && totalPendingMatches > 0) {
      setShouldRender(true);
    } else {
      setShouldRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPendingMatches, authenticatedUser]);

  if (!shouldRender) {
    return null;
  }

  const boldTextContent = `${totalPendingMatches} principals want to connect with you 🎉`;
  const regularTextContent =
    'Find your weekly matches in your candidate portal—click your avatar in the top right or click';

  const content = (
    <TextContainer>
      <BoldText sx={{ typographyStyles }}>{boldTextContent}</BoldText>
      <RegularText sx={{ typographyStyles }}>
        {regularTextContent} <BannerLink href={`/candidate/matches`}>here</BannerLink>.
      </RegularText>
    </TextContainer>
  );

  return (
    <>
      {shouldRender && (
        <AnnouncementBanner
          body={content}
          dataTestId={ConnectProfileFlowDataTestIds.CONNECT_ANNOUNCEMENT_BANNER}
          sx={bannerStyles(theme)}
        />
      )}
    </>
  );
};

// Styles
const bannerStyles = (theme: Theme) => ({
  position: 'sticky',
  top: '80px',
  zIndex: 1100,
  display: 'flex',
  direction: 'row',
  width: '100%',
  minHeight: '47px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  backgroundColor: theme.palette.purple.dark,
  textWrap: 'none',

  [theme.breakpoints.down('lg')]: {
    top: '64px',
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    textWrap: 'wrap',
  },
});

const TextContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

const typographyStyles = (theme: Theme) => ({
  color: '#FFF',
  textAlign: 'center',
  fontSize: theme.typography.heading7.fontSize,
  fontStyle: 'normal',
});

const BoldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: '30px',
}));

const RegularText = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '26px',
}));

const BannerLink = styled(Link)(({ theme }) => ({
  color: theme.palette.white.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.h3.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '26px',
  textDecoration: 'underline',
  textDecorationStyle: 'solid',
  textDecorationSkipInk: 'none',
  textUnderlineOffset: 'auto',
  textUnderlinePosition: 'from-font',
}));
