import { Skeleton } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CreateFormProps } from './types';
import { CTAButtons } from './CTAButtons';
import { QuestionsSubForm } from './QuestionsSubForm';
import { TemplateSelect } from './TemplateSelect';
import { useQueryGetAvailableScorecardsByRoleData } from '../queries';
import { ATSConnectHiringRequestCreateFeedbackTestIds } from 'data-testids/ATS';

export const CreateForm: React.FC<CreateFormProps> = ({ onCancel, roleId }) => {
  const { watch } = useFormContext();
  const { data: scorecards, isLoading } = useQueryGetAvailableScorecardsByRoleData(roleId);
  const id = watch('id');
  const selectedScorecard = scorecards?.find((scorecard) => scorecard.id === id);
  if (isLoading) {
    return (
      <Skeleton
        data-testid={ATSConnectHiringRequestCreateFeedbackTestIds.SELECT_LOADING_SKELETON}
        variant="rectangular"
        height={53}
      />
    );
  }

  return (
    <>
      <TemplateSelect scorecards={scorecards} />
      {selectedScorecard && (
        <QuestionsSubForm
          questions={selectedScorecard.questions}
          scorecardTitle={selectedScorecard.title}
        />
      )}
      <CTAButtons
        hasSelectedScorecard={Boolean(selectedScorecard)}
        onCancel={onCancel}
        onSaveAsDraft={() => {}}
      />
    </>
  );
};
