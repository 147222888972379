import { useRef } from 'react';
import { FieldValues, useFormContext, UseFormSetValue } from 'react-hook-form';
import { scorecardQType } from 'utils/typedEnums';
import { FeedbackQuestion } from './types';

export const assignQuestionProperties = (
  questions: FeedbackQuestion[],
  setValue: UseFormSetValue<FieldValues>
): void => {
  if (!questions) return;

  setValue(
    'answers',
    questions.map(({ id, question_type, is_required }) => {
      const baseAnswer = {
        question_id: id,
        question_type,
        is_required: is_required,
      };

      switch (question_type) {
        case scorecardQType.rating:
          return { ...baseAnswer, answer_rating: null };
        case scorecardQType.rubric:
          return { ...baseAnswer, answer_rubric: null };
        case scorecardQType.multiple_choice:
          return { ...baseAnswer, mc_answer: [] };
        case scorecardQType.text:
        case scorecardQType.nimble:
          return { ...baseAnswer, answer_text: '' };
        case scorecardQType.attachment:
          return { ...baseAnswer, attachment: null };
        default:
          return baseAnswer;
      }
    })
  );
};

export const assignTopLevelScorecardProperties = (
  questions: FeedbackQuestion[],
  setValue: UseFormSetValue<FieldValues>
): void => {
  return questions.forEach((question) => {
    if (
      question.question_type !== scorecardQType.final_recommendation &&
      question.question_type !== scorecardQType.cumulative_score
    ) {
      return;
    }

    switch (question.question_type) {
      case scorecardQType.final_recommendation:
        setValue('final_recommendation', null);
        setValue('include_final_recommendation', true);
        setValue('include_maybe_option', question.include_maybe_option);
        setValue('show_answer_on_preview', question.show_answer_on_preview);
        break;
      case scorecardQType.cumulative_score:
        setValue('cumulative_score', 0);
        setValue('include_cumulative_score', true);
        setValue('include_rating_questions', question.include_rating_questions);
        setValue('include_rubric_questions', question.include_rubric_questions);
        setValue('max_cumulative_score', 100); // hardcoded for now until endpoint is updated
        setValue('show_total_on_preview', question.show_total_on_preview);
        break;
      default:
        break;
    }
  });
};

export const useUpdateCumulativeScore = (showQuestions: boolean) => {
  const { watch, setValue } = useFormContext();
  const previousValue = useRef(0);

  const updateScore = (value: number) => {
    if (!showQuestions) return;

    const newScore = isNaN(value) ? 0 : value;
    const adjustment = newScore - previousValue.current;

    const pastCumulativeScore = watch('cumulative_score');
    const newTotalScore = pastCumulativeScore + adjustment;

    setValue('cumulative_score', newTotalScore), (previousValue.current = newScore);
  };

  return updateScore;
};
