import { useState } from 'react';

import { FinalRecommendationOption, FinalRecommendationQuestionProps } from './types';
import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { QuestionLabel } from './QuestionLabel';

const YES: FinalRecommendationOption = { alignment: 'left', text: 'Yes' };
const MAYBE: FinalRecommendationOption = { alignment: 'middle', text: 'Maybe' };
const NO: FinalRecommendationOption = { alignment: 'right', text: 'No' };

export const allFinalRecommendationOptions = [YES, MAYBE, NO];
export const shortenedFinalRecommendationOptions = [YES, NO];

const getInitialAlignment = (defaultValue) => {
  if (!defaultValue) return null;

  const alignmentMap = {
    'final_recommendation/yes': 'left',
    'final_recommendation/maybe': 'middle',
    'final_recommendation/no': 'right',
  };
  return alignmentMap[defaultValue] || 'left';
};

export const FinalRecommendationQuestion: React.FC<FinalRecommendationQuestionProps> = ({
  dataTestId,
  isRequired,
  onToggle,
  includeMaybeOption,
  questionText,
  defaultValue,
}) => {
  const [alignment, setAlignment] = useState<string | null>(getInitialAlignment(defaultValue));
  const theme = useTheme();

  const options = includeMaybeOption
    ? allFinalRecommendationOptions
    : shortenedFinalRecommendationOptions;

  const handleAlignment = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: 'left' | 'middle' | 'right' | null
  ) => {
    setAlignment(newAlignment);
    if (newAlignment === 'left') {
      onToggle('final_recommendation/yes');
    } else if (newAlignment === 'middle') {
      onToggle('final_recommendation/maybe');
    } else if (newAlignment === 'right') {
      onToggle('final_recommendation/no');
    }
  };

  return (
    <>
      <QuestionLabel
        dataTestId={dataTestId}
        questionText={questionText}
        sx={{ paddingBottom: theme.spacing(0.5) }}
        isRequired={isRequired}
      />
      <ToggleButtonGroup color="primary" data-testid={dataTestId} exclusive value={alignment}>
        {options.map((option) => (
          <ToggleButton
            data-testid={`${dataTestId}-toggle-button-${option.alignment}`}
            key={option.alignment}
            value={option.alignment}
            onClick={handleAlignment}
          >
            {option.text}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};
